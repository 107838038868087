import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  connected: false,
  account: {
    avatar: '',
    pageLists: [
      {
        id: 1,
        name: 'Qontak Official',
      },
      {
        id: 2,
        name: 'Qontak RND',
      },
    ],
  },
  accountDetail: {
    id: '',
    email: '',
    password: '',
    smtp_host: '',
    smtp_port: '',
    protocol: '',
    forwaded_email: '',
  },
  integrationLists: [],
})

export const getters = {
  getField,
}

export const actions = {
  getEmailConnection({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_URL}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_LIST', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getEmailConnectionByPage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_URL}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_LIST', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getEmailIntegrationDetail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_URL}/${params.id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_ACCOUNT_DETAIL', {
              ...res.data,
              settings: {
                ...res.data.settings,
                email: res.data.settings.username,
              },
            })
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateEmailIntegration({ commit, state }, params) {
    if (params.id) {
      return new Promise((resolve, reject) => {
        requests.whatsapp
          .putService(
            `${INTEGRATION_URL}/email/${params.id}`,
            state.accountDetail,
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            (res) => {
              commit('UPDATE_ACCOUNT_DETAIL', res.data)
              resolve(res.data)
            },
            (err) => {
              commit(
                'layouts/UPDATE_NOTIFICATION',
                {
                  display: true,
                  type: 'failed',
                  message: 'Error!',
                  item: err.error.messages[0],
                  callback: {
                    text: 'OK',
                    method: 'closeNotification',
                  },
                },
                { root: true }
              )
              reject(err.error.messages[0])
            }
          )
      })
    } else {
      return new Promise((resolve, reject) => {
        requests.whatsapp
          .postService(`${INTEGRATION_URL}/email`, state.accountDetail, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('UPDATE_ACCOUNT_DETAIL', res.data)
              resolve(res.data)
            },
            (err) => {
              commit(
                'layouts/UPDATE_NOTIFICATION',
                {
                  display: true,
                  type: 'failed',
                  message: 'Error!',
                  item: err.error.messages[0],
                  callback: {
                    text: 'OK',
                    method: 'closeNotification',
                  },
                },
                { root: true }
              )
              reject(err.error.messages[0])
            }
          )
      })
    }
  },
  disconnect({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .deleteService(`${INTEGRATION_URL}/${params.id}`, null, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Disconnect!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'refreshEmailIntegration',
                },
              },
              { root: true }
            )
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  createOutlookMail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/email/outlook`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_LIST(state, payload) {
    state.integrationLists = payload
  },
  PUSH_LIST(state, payload) {
    payload.forEach((data) => {
      state.integrationLists.push(data)
    })
  },
  UPDATE_ACCOUNT_DETAIL(state, payload) {
    state.accountDetail = { ...payload.settings, id: payload.id }
  },
}
