import _ from 'lodash-core'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  connected: false,
  account: {
    avatar: '',
    pageLists: [
      {
        id: 1,
        name: 'Qontak Official',
      },
      {
        id: 2,
        name: 'Qontak RND',
      },
    ],
  },
  accountDetail: {
    avatar: '',
    account: 'Qontakdotcom',
    selectedPage: 'Qontak Pte. Ltd',
  },
  integrationLists: [],
  reconnectData: null,
})

export const getters = {
  getField,
}

export const actions = {
  getConnection({ commit, state }, params) {
    commit('SET_RECONNECT_DATA', null)

    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${INTEGRATION_URL}`, params.parameter, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          const { data } = res
          const fbChannel = _.filter(data, { target_channel: 'fb' })

          commit('SET_DATA', fbChannel)

          // check token validity
          if (fbChannel.length && params.showReconnect) {
            fbChannel.forEach((channel, idx) => {
              requests.whatsapp
                .getService(
                  `${INTEGRATION_URL}/fb/validate_token/${channel.id}`,
                  null,
                  { Authorization: this.$auth.getToken('hub') }
                )
                .subscribe(
                  (res) => {
                    const isValid = res.data.is_valid
                    const channelData = {
                      ...fbChannel[idx],
                      is_valid: isValid,
                    }

                    commit('UPDATE_CONNECTION_DATA', channelData)

                    if (!isValid && !state.reconnectData) {
                      commit('SET_RECONNECT_DATA', channelData)
                      commit(
                        'layouts/UPDATE_NOTIFICATION',
                        {
                          display: true,
                          type: 'warning',
                          item: `We’ve lost access to your Facebook Messenger account (${channelData.settings.page_name}). Please reconnect with Facebook.`,
                          callback: {
                            text: 'Reconnect',
                            method: 'redirectToFbReconnect',
                          },
                        },
                        { root: true }
                      )
                    }
                  },
                  (_err) => {
                    const channelData = {
                      ...fbChannel[idx],
                      is_valid: true,
                    }
                    commit('UPDATE_CONNECTION_DATA', channelData)
                  }
                )
            })
          }

          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  connect({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/fb_messenger`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Connected!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'refreshFacebookIntegration',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0]
                  ? err.error.messages[0]
                  : 'Oops, something wrong.',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  disconnect({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${INTEGRATION_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Disconnect!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshFacebookIntegration',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getLongLivedToken({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    return new Promise((resolve, reject) => {
      requests.channels
        .getService(`${INTEGRATION_URL}/fb/exchange_token/${params}`, null, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  reconnect({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(
          `${INTEGRATION_URL}/fb/update_token/${params.channel_id}`,
          params,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Reconnected!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0]
                  ? err.error.messages[0]
                  : 'Oops, something wrong.',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })

            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DATA(state, payload) {
    state.integrationLists = payload
  },
  UPDATE_CONNECTION_DATA(state, payload) {
    const idx = state.integrationLists.findIndex(
      (list) => list.id === payload.id
    )

    if (idx > -1) {
      state.integrationLists.splice(idx, 1, payload)
    }
  },
  SET_RECONNECT_DATA(state, payload) {
    state.reconnectData = payload
  },
}
