import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  integrationLists: [],
  integrationDetail: [],
})

export const getters = {
  getField,
}

export const actions = {
  getConnection({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${INTEGRATION_URL}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATA', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  connect({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${INTEGRATION_URL}/direct_qiscus`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Connected!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshQiscusIntegration',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0]
                ? err.error.messages[0]
                : 'Oops, something wrong.',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  // getConnectionDetail({ commit, state }, params) {
  //   commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
  //   requests.whatsapp.getService(`${QISCUS_INTEGRATION_URL}/${params.id}`, params, { Authorization: this.$auth.getToken('hub') }).subscribe(
  //     (res) => {
  //       commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
  //       commit('UPDATE_DETAIL', res.data.response)
  //     },
  //     (err) => {
  //       commit(
  //         'layouts/UPDATE_NOTIFICATION',
  //         {
  //           display: true,
  //           type: 'failed',
  //           message: 'Error!',
  //           item: err.error.messages[0],
  //           callback: {
  //             text: 'OK',
  //             method: 'closeNotification'
  //           }
  //         },
  //         { root: true }
  //       )
  //       commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
  //     }
  //   )
  // },
  disconnect({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${INTEGRATION_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Disconnect!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshQiscusIntegration',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  // updateIntegration({ commit, state }, params) {
  //   commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
  //   requests.whatsapp.postService(`${QISCUS_INTEGRATION_URL}/${params.id}`, params, { Authorization: this.$auth.getToken('hub') }).subscribe(
  //     (res) => {
  //       commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
  //       commit(
  //         'layouts/UPDATE_NOTIFICATION',
  //         {
  //           display: true,
  //           type: 'success',
  //           message: 'Successfully Updated!',
  //           item: '',
  //           callback: {
  //             text: 'OK',
  //             method: 'refreshQiscusIntegration'
  //           }
  //         },
  //         { root: true }
  //       )
  //     },
  //     (err) => {
  //       commit(
  //         'layouts/UPDATE_NOTIFICATION',
  //         {
  //           display: true,
  //           type: 'failed',
  //           message: 'Error!',
  //           item: err.error.messages[0],
  //           callback: {
  //             text: 'OK',
  //             method: 'closeNotification'
  //           }
  //         },
  //         { root: true }
  //       )
  //       commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
  //     }
  //   )
  // }
}

export const mutations = {
  updateField,
  SET_DATA(state, payload) {
    state.integrationLists = payload
  },
  UPDATE_DETAIL(state, payload) {
    state.integrationDetail = payload
  },
}
