import _ from 'lodash-core'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  integrationLists: [],
  detail: {},
})

export const getters = {
  getField,
}

export const actions = {
  getConnection({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${INTEGRATION_URL}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/SET_META', res.meta, { root: true })

          const { data } = res
          const channel = _.filter(data, { target_channel: 'line' })

          commit('SET_DATA', channel)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  getConnectionDetail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content', status: true },
        { root: true }
      )

      requests.whatsapp
        .getService(
          `${INTEGRATION_URL}/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_ACCOUNT_DETAIL', res.data.settings)
            resolve(res.data.settings)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
          }
        )
    })
  },
  connect({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/line`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Connected!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'refreshLineIntegration',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0]
                  ? err.error.messages[0]
                  : 'Oops, something wrong.',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  disconnect({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${INTEGRATION_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Disconnect!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshLineIntegration',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateConnection({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${INTEGRATION_URL}/line/${params.channel_id}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Updated!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'refreshLineIntegration',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0]
                  ? err.error.messages[0]
                  : 'Oops, something wrong.',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })

            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DATA(state, payload) {
    state.integrationLists = payload
  },
  UPDATE_ACCOUNT_DETAIL(state, payload) {
    state.detail = payload
  },
}
