import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  mobilechatIntegration: [],
  mobilechatIntegrationDetail: {
    is_active: false,
    settings: {
      app_id: null,
    },
  },
})

export const actions = {
  getMobilechatIntegrations({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .getService(`${INTEGRATION_URL}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_MOBILECHAT_INTEGRATION_LIST', res.data)
            resolve()
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getWebchatIntegrationDetail({ commit, dispatch, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.webchat
      .getService(
        `${INTEGRATION_URL}/webchat/widgets`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        async (res) => {
          await commit('SET_MOBILECHAT_INTEGRATION_LIST', res.data)
          await commit('SET_WEBCHAT_INTEGRATION_DETAIL', params)
          await commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getMobilechatIntegrationDetail({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .getService(
          `${INTEGRATION_URL}/${params.channel_id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_MOBILECHAT_INTEGRATION_DETAIL', res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateMobilechatActivation({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(
          `${INTEGRATION_URL}/${params.id}/status`,
          { is_active: params.isActive },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            commit('UPDATE_INTEGRATION_STATUS', !params.isActives)
            reject(err)
          }
        )
    })
  },
  uninstallApps({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .deleteService(
          `${INTEGRATION_URL}/app_chat/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateAppName({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(
          `${INTEGRATION_URL}/app_chat/${params.id}/app_name`,
          { app_name: params.appName },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_MOBILECHAT_NAME', params.appName)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updatePlatforms({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(
          `${INTEGRATION_URL}/app_chat/${params.id}/bundle_ids`,
          { bundle_ids: params.platforms },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_MOBILECHAT_PLATFORMS', params.platforms)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateFcmServerKey({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(
          `${INTEGRATION_URL}/app_chat/${params.id}/push_notification`,
          { fcm_server_key: params.key },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_FCM_SERVER_KEY', params.key)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateContentAppearance({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .putService(
          `${INTEGRATION_URL}/app_chat/${params.id}/widget_setting`,
          { widget_setting: params.parameter },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_CONTENT_AND_APPEARANCE', params.parameter)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  createMobilechatIntegration({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .postService(`${INTEGRATION_URL}/app_chat`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  removeFcmKey({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      requests.webchat
        .deleteService(
          `${INTEGRATION_URL}/app_chat/${params}/push_notification`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
            commit('UPDATE_FCM_SERVER_KEY', null)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  SET_MOBILECHAT_INTEGRATION_LIST(state, payload) {
    state.mobilechatIntegration = payload
  },
  SET_MOBILECHAT_INTEGRATION_DETAIL(state, payload) {
    state.mobilechatIntegrationDetail = payload
  },
  UPDATE_INTEGRATION_STATUS(state, payload) {
    state.mobilechatIntegrationDetail.is_active = payload
  },
  UPDATE_MOBILECHAT_NAME(state, payload) {
    state.mobilechatIntegrationDetail.settings.app_name = payload
  },
  UPDATE_MOBILECHAT_PLATFORMS(state, payload) {
    state.mobilechatIntegrationDetail.settings.bundle_ids = payload
  },
  UPDATE_FCM_SERVER_KEY(state, payload) {
    state.mobilechatIntegrationDetail.settings.fcm_server_key = payload
  },
  UPDATE_CONTENT_AND_APPEARANCE(state, payload) {
    state.mobilechatIntegrationDetail.settings.widget_setting = payload
  },
  REMOVE_MOBILE_CHAT_DETAIL(state) {
    state.mobilechatIntegrationDetail = {
      is_active: false,
      settings: {
        app_id: null,
      },
    }
  },
}
