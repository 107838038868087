import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
import requests from '../../requests'
import { INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  integrationLists: [],
  reconnectData: null,
  organizationHasSubmitted: false,
})

export const getters = {
  getField,
}

export const actions = {
  getConnection({ commit, state }, showReconnect) {
    commit('SET_RECONNECT_DATA', null)

    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${INTEGRATION_URL}`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          const { data } = res
          const igChannel = _.filter(data, { target_channel: 'ig' })

          commit('SET_DATA', igChannel)

          // check token validity
          if (igChannel.length && showReconnect) {
            igChannel.forEach((channel, idx) => {
              requests.whatsapp
                .getService(
                  `${INTEGRATION_URL}/fb/validate_token/${channel.id}`,
                  null,
                  { Authorization: this.$auth.getToken('hub') }
                )
                .subscribe(
                  (res) => {
                    const isValid = res.data.is_valid
                    const channelData = {
                      ...igChannel[idx],
                      is_valid: isValid,
                    }

                    commit('UPDATE_CONNECTION_DATA', channelData)

                    if (!isValid && !state.reconnectData) {
                      commit('SET_RECONNECT_DATA', channelData)
                      commit(
                        'layouts/UPDATE_NOTIFICATION',
                        {
                          display: true,
                          type: 'warning',
                          item: `We’ve lost access to your Facebook Messenger account (${channelData.settings.page_name}). Please reconnect with Facebook.`,
                          callback: {
                            text: 'Reconnect',
                            method: 'redirectToFbReconnect',
                          },
                        },
                        { root: true }
                      )
                    }
                  },
                  (_err) => {
                    const channelData = {
                      ...igChannel[idx],
                      is_valid: true,
                    }
                    commit('UPDATE_CONNECTION_DATA', channelData)
                  }
                )
            })
          }

          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  getConnection2({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${INTEGRATION_URL}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          const list = res.data
            ? res.data.filter((d) => d.target_channel === 'ig')
            : []

          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATA', list)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  connect({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/instagram`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Connected!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'refreshInstagramIntegration',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0]
                  ? err.error.messages[0]
                  : 'Oops, something wrong.',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  disconnect({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${INTEGRATION_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Disconnect!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshInstagramIntegration',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getLongLivedToken({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    return new Promise((resolve, reject) => {
      requests.channels
        .getService(`${INTEGRATION_URL}/fb/exchange_token/${params}`, null, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DATA(state, payload) {
    state.integrationLists = payload
  },
  SET_RECONNECT_DATA(state, payload) {
    state.reconnectData = payload
  },
  UPDATE_CONNECTION_DATA(state, payload) {
    const idx = state.integrationLists.findIndex(
      (list) => list.id === payload.id
    )

    if (idx > -1) {
      state.integrationLists.splice(idx, 1, payload)
    }
  },
}
